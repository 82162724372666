import React, { useContext } from "react";
import ChevronLeftIcon from "../../../assets/images/chevron-left-icon.svg";
import { TestContext, nextStepResolver } from "../../utils/runTest";
import { TestContextType } from "../../utils/types";
import Loader from "./Loader";
import MeasurementTimer from "./MeasurementTimer";
import { addSampleInstructions } from "../../utils/tests";
import InsertCartridge from "./InsertCartridge";
import AddSampleOnCartridge from "./AddSampleOnCartridge";

const AddSample: React.FC = () => {
  const context = useContext<TestContextType>(TestContext);
  const { testState, currentStep } = context;
  const { measurement } = testState

  if (measurement.inProgress && measurement.runTimer) {
    return <MeasurementTimer isTimerRunning={testState.measurement.runTimer} title={measurement.message} description={measurement?.description} />;
  }

  if (measurement.inProgress) {
    return <Loader title={measurement.message} description={measurement.description} />;
  }

  if (testState.measurementSuccessAlert) {
    return (
      <div className="flexed-center flex-column w-100 h-100">
        <h5 className="fs-5 fw-bold text-center w-25">{testState.measurementSuccessAlert?.message}</h5>
        <button className="stepper__btn btn-lg" onClick={() => nextStepResolver(context)}>
          Proceed
          <img src={ChevronLeftIcon} style={{ rotate: "180deg" }} />
        </button>
      </div>
    );
  }

  return (
    <>
      <h3 className="text-center mb-3 mt-2">{currentStep?.description || addSampleInstructions[testState.test.test_definition_id].heading}</h3>
      <div className="add-sample">
        {
          currentStep?.description ? (
            <>
              <InsertCartridge />
              <AddSampleOnCartridge />
            </>
          ) : addSampleInstructions[testState.test.test_definition_id].instructions.map((Instruction, i) => <Instruction key={i}/>)
        }
      </div>
    </>
  );
};

export default AddSample;
