import React from "react";
import ConicalFlaskIcon from "../../../assets/images/conical-flask-icon.svg"

type Props = { heading: string;
  info: string,
  image: string,
  stat?: string,
  beta?: boolean,
  imageSize?: string
}

const SelectionCard: React.FC<Props> = ({ heading, info, image, stat, beta = false, imageSize = "100px" }) => {
  return (
    <div className="selection-card">
      <img src={image} width={imageSize} />{" "}
      <div className="d-flex flex-column w-100">
        <h6 className="text-qsm-green selection-card__heading">{heading}</h6>
        <p className="text-warning selection-card__stats">{stat}</p>
        <p className="text-qsm-grey selection-card__info">{info}</p>
      </div>

      { beta && (
        <div className="selection-card__beta-chip">
          <img src={ConicalFlaskIcon} className="selection-card__beta-chip__icon" />
          <span className="m-0">BETA</span>
        </div>
      )}
    </div>
  );
};

export default SelectionCard;
