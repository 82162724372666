import React, { useEffect, useState } from "react";
import { Spinner } from "./Loader";
import { DateTime } from "luxon";

type Props = {
  isTimerRunning: boolean;
  title: string;
  description: string;
};

const MeasurementTimer: React.FC<Props> = ({ isTimerRunning, title, description }) => {
  const [time, setTime] = useState<{ minutes: number; seconds: number }>({ minutes: 0, seconds: 0 });
  const [startTime, setStartTime] = useState(DateTime.fromISO(DateTime.now().toString()));

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isTimerRunning) {
      // setting time from 0 to 1 every 1 second using javascript setInterval method
      const duration = DateTime.fromISO(DateTime.now().toString()).diff(startTime, ["minutes", "seconds"]).toObject();
      intervalId = setInterval(() => setTime({ minutes: Math.floor(duration.minutes), seconds: Math.floor(duration.seconds) }), 1000);
    }
    return () => clearInterval(intervalId);
  }, [isTimerRunning, time]);

  return (
    <div className="flexed-center flex-column h-100 w-100">
      <div className="flexed-center gap-2">
        <Spinner />
        <h3 className="m-0 fw-bold fs-2 timer">
          {time.minutes}:{time.seconds}
        </h3>
      </div>
      <p className="fs-4 m-0">{title}</p>
      <p className="fw-light text-muted text-sm m-0 w-25 text-center">{description}</p>
    </div>
  );
};

export default MeasurementTimer;
