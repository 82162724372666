import { createContext } from "react";
import PairNewInstrument from "../components/runtest/PairNewInstrument";
import { ResolveBtn, TEST_STEPS, STEP_STATUS, Step, Steps, StepsStateType, TestContextType, TestStateType, Test, TESTS } from "./types";
import SelectPetDetails from "../components/runtest/SelectPetDetails";
import SelectTest from "../components/runtest/SelectTest";
import AddSample from "../components/runtest/AddSample";
import { DeviceInfo, getInstrumentError, getInstrumentInfo, readStatic, runMeasurementStatic } from "../packs/palmsense_lib/instrument";
import TestCompleted from "../components/runtest/TestCompleted";
import { getAvailableTests, submitTestData } from "./services";

export const testSteps: Steps = [
  {
    id: TEST_STEPS.CONNECT,
    title: "CONNECT",
    comp: PairNewInstrument,
    description: "Steps To Connect Your Instrument",
    status: STEP_STATUS.INPROGRESS,
    next: TEST_STEPS.ADD_PET,
    initial: true,
    guards: {
      on: {
        next: (context) => {
          resolveBtn({ context, disabled: context.testState.test.pet_id ? false : true, on: "next" });
          resolveBtn({ context, disabled: true, label: "Back", on: "prev" });
        },
      },
    },
  },
  {
    id: TEST_STEPS.ADD_PET,
    title: "ADD PET",
    comp: SelectPetDetails,
    description: "Add Pet Details",
    status: STEP_STATUS.INCOMPLETE,
    next: TEST_STEPS.SELECT_TEST,
    prev: TEST_STEPS.CONNECT,
    guards: {
      on: {
        next: (context) => {
          resolveBtn({ context, disabled: true, label: "Run Test", on: "next" });
          resolveBtn({ context, disabled: false, label: "Back", on: "prev" });
        },
        prev: (context) => {
          resolveBtn({ context, disabled: false, on: "next" });
        },
      },
    },
  },
  {
    id: TEST_STEPS.SELECT_TEST,
    title: "SELECT TEST",
    comp: SelectTest,
    status: STEP_STATUS.INCOMPLETE,
    next: TEST_STEPS.ADD_SAMPLE,
    prev: TEST_STEPS.ADD_PET,
    guards: {
      on: {
        next: (context) => {
          context.setCountDown(0);
          resolveNextForSelectTest(context);
        },
        prev: (context) => {
          const { setTestState, testState, setCountDown } = context;

          resolveBtn({ context, disabled: false, label: "Proceed", on: "next", action: nextStepResolver });
          resolveBtn({ context, disabled: true, label: "Back", on: "prev" });
          setTestState({ ...testState, test: { ...testState.test, test_definition_id: null } });
          setCountDown(0);
        },
      },
    },
  },
  {
    id: TEST_STEPS.ADD_SAMPLE,
    title: "ADD SAMPLE",
    comp: AddSample,
    status: STEP_STATUS.INCOMPLETE,
    next: TEST_STEPS.COMPLETE,
    prev: TEST_STEPS.SELECT_TEST,
    guards: {
      on: {
        prev: (context) => {
          const { setTestState } = context
          setTestState((prev) => ({ ...prev,  measurement: testInitialState.measurement, alerts: [] }));
          resetEisSteps(context)
        },
      },
    },
  },
  {
    id: TEST_STEPS.COMPLETE,
    title: "DONE",
    comp: TestCompleted,
    status: STEP_STATUS.INCOMPLETE,
    last: true,
  },
];

const eisStep: Step = {
  id: TEST_STEPS.ADD_BLANK,
  title: "ADD BLANK",
  comp: AddSample,
  description: "Add Blank Sample Buffer on Cartridge & Run Test",
  status: STEP_STATUS.INCOMPLETE,
  next: TEST_STEPS.ADD_SAMPLE,
  prev: TEST_STEPS.SELECT_TEST,
  guards: {
    on: {
      next: (context) => {
        context.setTestState((prev) => ({ ...prev, measurementSuccessAlert: null }));
        resolveBtn({ context, disabled: false, on: "next", label: "Run Test", action: runEISMeasurement });
      },
      prev: (context) => {
        const { steps, setStepsState } = context;
        context.setTestState((prev) => ({ ...prev, measurement: testInitialState.measurement, alerts: [] }));
        resetEisSteps(context)
      },
    },
  },
};

export const stepsConfig = {
  initial: testSteps.find((step) => step.id === TEST_STEPS.CONNECT),
  last: TEST_STEPS.COMPLETE,
};

export const initialStepState: StepsStateType = {
  steps: testSteps.map((step) => Object.assign({}, step)),
  currentStep: stepsConfig.initial,
  btn: {
    next: {
      label: "Proceed",
      disabled: false,
      action: nextStepResolver,
    },
    prev: {
      label: "Back",
      disabled: true,
      action: prevStepResolver,
    },
  },
};

export const firmwareVersions = {
  "1.2": 0,
  "1.3": 1,
};

export const testInitialState: TestStateType = {
  selectedPet: null,
  test: {
    raw_result: [],
    pet_id: null,
    sample_area: 0,
    test_definition_id: null,
    firmware_version: null,
  },
  measurement: { inProgress: false, message: "Running Measurement", description: "Please Stay on The Screen", runTimer: false },
  measurementSuccessAlert: null,
  tests: [],
  alerts: [],
  pairingInstrument: false,
};

export const TestContext = createContext<TestContextType>(null);

export function nextStepResolver(context: TestContextType) {
  const { setStepsState, currentStep } = context;

  const guard = currentStep.guards?.on?.next;
  if (guard) guard(context);

  setStepsState(({ currentStep, steps, btn }) => {
    let currentStepIndex = steps.findIndex((step) => step.id === currentStep.id);
    steps[currentStepIndex].status = STEP_STATUS.DONE;
    if (!currentStep.last) {
      let nextStep = steps.find((step) => step.id === currentStep?.next);
      nextStep.status = STEP_STATUS.INPROGRESS;
      currentStep = nextStep;
    }

    return { steps, currentStep, btn };
  });
}

export function prevStepResolver(context: TestContextType) {
  const { setStepsState, currentStep } = context;

  const guard = currentStep.guards?.on?.prev;
  if (guard) guard(context);

  setStepsState(({ currentStep, steps, btn }) => {
    let currentStepIndex = steps.findIndex((step) => step.id === currentStep.id);
    steps[currentStepIndex].status = STEP_STATUS.INCOMPLETE;

    let prevStep = steps.find((step) => step.id === currentStep?.prev);
    prevStep.status = STEP_STATUS.INPROGRESS;

    return { steps, currentStep: prevStep, btn };
  });
}

export function resolveBtn({ context, disabled, label, action, on }: ResolveBtn) {
  context.setStepsState((prev: StepsStateType) => ({
    ...prev,
    btn: {
      ...prev.btn,
      [on]: {
        ...prev.btn[on],
        disabled: disabled,
        ...(label && { label: label }),
        ...(action && { action: action }),
      },
    },
  }));
}

function resetEisSteps(context: TestContextType) {
  const { steps, setStepsState } = context
  const EISStepIndex = steps.findIndex((step) => step.id == TEST_STEPS.ADD_BLANK);

  if (EISStepIndex >= 0) {
    setStepsState((state) => {
      steps[EISStepIndex - 1].next = TEST_STEPS.ADD_SAMPLE;
      state.currentStep = steps.find((step) => step.id == steps[EISStepIndex].next);
      steps.splice(EISStepIndex, 1);
      return { ...state, steps: steps };
    });
  }
}
export const testNames: object = {
  [TESTS.RAPID_TEST]: { [TEST_STEPS.ADD_SAMPLE]: "rapid_test" },
  [TESTS.OVERNIGHT_TEST]: { [TEST_STEPS.ADD_SAMPLE]: "overnight_test" },
  [TESTS.EIS_TEST]: {
    [TEST_STEPS.ADD_BLANK]: "eis_blank",
    [TEST_STEPS.ADD_SAMPLE]: "eis_test",
  },
};

export function startTimer(context: TestContextType) {
  const { setCountDown } = context;
  resolveBtn({ context, disabled: true, on: "next" });
  const timer = setInterval(() => {
    setCountDown((countDown) => {
      countDown = countDown - 1;

      if (countDown > 0) {
        return countDown;
      }

      if (countDown == 0){
        resolveBtn({ context, disabled: false, label: "Proceed", on: "next", action: nextStepResolver });
      }

      clearInterval(timer);
    });
  }, 1000);
}

export async function instrumentConnectCallback(context: TestContextType) {
  const { setTestState, connectedInstruments } = context;

  setPairingInstrumentStatus({ context, status: true });
  resolveBtn({ context, disabled: true, on: "next" });
  const connectionStatus = getConnectionStatus(connectedInstruments);

  try {
    if (connectionStatus.isConnected) {
      const { firmwareVersion, serialNumber, errorCode }: DeviceInfo = await getInstrumentInfo(connectedInstruments[0]);

      if(errorCode) {
        return setTestState((prev) => ({ ...prev, alerts: [ { message: getInstrumentError(errorCode).message, type: "error" }], tests: [] }));
      }

      const getTests: { available_tests: Test[]; message: string } = await getAvailableTests(serialNumber);

      if (getTests?.available_tests) {
        return setTestState((prev) => ({ ...prev, tests: getTests.available_tests, test: { ...prev.test, firmware_version: firmwareVersion } }));
      }

      if (getTests.message) {
        return setTestState((prev) => ({ ...prev, alerts: [ { message: `${getTests.message}`, type: "error" }], tests: [] }));
      }
    }

    return resetToNew(context);
  } catch (error) {
    console.log(error);
  } finally {
    setPairingInstrumentStatus({ context, status: false });
  }
}

export async function setPairingInstrumentStatus({ context, status }: { context: TestContextType; status: boolean }) {
  context.setTestState((prev) => ({ ...prev, pairingInstrument: status }));
}

export function onTestSelectCallback(context: TestContextType) {
  const { testState, setCountDown } = context;
  switch (testState.test.test_definition_id) {
    case TESTS.RAPID_TEST:
      resolveBtn({ context, disabled: false, label: "Start Timer", on: "next", action: startTimer });
      setCountDown(60);
      break;
    case TESTS.OVERNIGHT_TEST:
      resolveBtn({ context, disabled: false, label: "Proceed", on: "next", action: nextStepResolver });
      setCountDown(0);
      break;
    case TESTS.EIS_TEST:
      resolveBtn({ context, disabled: false, label: "Proceed", on: "next", action: nextStepResolver });
      setCountDown(0);
      break;
    default:
      resolveBtn({ context, disabled: true, on: "next", action: nextStepResolver });
      setCountDown(0);
      break;
  }
}

export async function resolveNextForSelectTest(context: TestContextType) {
  const { testState, setStepsState } = context;
  switch (testState.test.test_definition_id) {
    case TESTS.EIS_TEST:
      // add new EIS steps
      setStepsState(({ currentStep, steps, btn }) => {
        let currentStepIndex = steps.findIndex((step) => step.id === currentStep.id);

        steps[currentStepIndex].next = eisStep.id;
        steps.splice(currentStepIndex + 1, 0, eisStep);
        return { steps, btn, currentStep: currentStep };
      });
      resolveBtn({ context, disabled: false, on: "next", label: "Run Blank", action: runEISBlank });
      break;
    default:
      // attach next action with run measurement
      resolveBtn({ context, disabled: false, on: "next", label: "Run Test", action: runMeasurement });
      break;
  }
}

export const stepStateClasses = {
  [STEP_STATUS.INCOMPLETE]: "step__circle-incomplete",
  [STEP_STATUS.INPROGRESS]: "step__circle-inprogress",
  [STEP_STATUS.DONE]: "step__circle-done",
};

export function getConnectionStatus(connectedInstruments: SerialPort[]) {
  const isConnected: boolean = connectedInstruments.length == 1;
  let status: string = "searching for instrument...";
  let connectionClass: string = "";

  if (isConnected) {
    connectionClass = "instrument-connection__indicator-connected";
    status = "Instrument Connected";
  }

  return { isConnected, connectionClass, status };
}

export async function runMeasurement(context: TestContextType) {
  const { setTestState } = context;

  setTestState((prev) => ({
    ...prev,
    measurement: { ...prev.measurement, inProgress: true, runTimer: false },
    alerts: [...prev.alerts, { message: "Please do not disconnect the instrument or refresh the page while running the measurement", type: "info" }],
  }));
  resolveBtn({ context, disabled: true, on: "next" });
  resolveBtn({ context, disabled: true, on: "prev" });

  try {
    if(await runMeasurementAndSave(context)) {
      setTestState((prev) => ({
        ...prev,
        measurement: testInitialState.measurement,
      }));

      return nextStepResolver(context);
    }

    setTestState((prev) => ({
      ...prev,
      alerts: [...prev.alerts, { message: "Error Saving Measurement", type: "error", clear: true }],
      measurement: testInitialState.measurement,
    }));
    resolveBtn({ context, disabled: false, on: "prev" });
  } catch (error) {
    setTestState((prev) => ({ ...prev, measurement: testInitialState.measurement, alerts: [{ message: "Error Running Measurement", type: "error" }] }));
    resolveBtn({ context, disabled: false, on: "prev" });
  }
}

export async function runEISBlank(context: TestContextType) {
  const { testState, setTestState, currentStep, connectedInstruments } = context;
  resolveBtn({ context, disabled: true, on: "next" });
  resolveBtn({ context, disabled: true, on: "prev" });
  setTestState((prev) => ({
    ...prev,
    alerts: [...prev.alerts, { message: "Please do not disconnect the instrument or refresh the page while running the measurement", type: "info" }],
    measurement: { inProgress: true, runTimer: true, message: "Running Blank", description: "This step will take around 11 minutes"}
  }));

  try {
    const port = connectedInstruments[0];
    const measurement = await runMeasurementStatic(port, testState.test.firmware_version, testState.test.test_definition_id, currentStep.id);

    setTestState((prev) => {
      const testname = testNames[testState.test.test_definition_id][currentStep.id];
      prev.test.raw_result.push({ name: testname, measurement: measurement });
      prev.measurement = testInitialState.measurement;
      prev.measurementSuccessAlert = { message: "Blank Measurement Taken Successfully, Proceed to Next Step" };
      prev.alerts = [];
      return prev;
    });

    resolveBtn({ context, disabled: false, on: "next", label: "Proceed", action: nextStepResolver });
  } catch (error) {
    setTestState((prev) => ({ ...prev, measurement: testInitialState.measurement, alerts: [{ message: "Error Running Measurement", type: "error" }] }));
    resolveBtn({ context, disabled: false, on: "prev" });
  }
}

export async function runEISMeasurement(context: TestContextType) {
  const { setTestState } = context;
  setTestState((prev) => ({
    ...prev,
    measurement: { inProgress: true, runTimer: true, message: "Running Measurement", description: "This step will take around 6 minutes" },
    alerts: [...prev.alerts, { message: "Please do not disconnect the instrument or refresh the page while running the measurement", type: "info" }],
  }));
  resolveBtn({ context, disabled: true, on: "next" });
  resolveBtn({ context, disabled: true, on: "prev" });

  try {
    if(await runMeasurementAndSave(context)) {
      setTestState((prev) => ({
        ...prev,
        measurement: testInitialState.measurement,
      }));

      return nextStepResolver(context);
    }

    setTestState((prev) => ({
      ...prev,
      alerts: [...prev.alerts, { message: "Error Saving Measurement", type: "error", clear: true }],
      measurement: testInitialState.measurement,
    }));
    resolveBtn({ context, disabled: false, on: "prev" });
  } catch (error) {
    setTestState((prev) => ({ ...prev, measurement: testInitialState.measurement, alerts: [{ message: "Error Running Measurement", type: "error" }] }));
    resolveBtn({ context, disabled: false, on: "prev" });
  }
}

export async function runMeasurementAndSave(context: TestContextType) {
  const { connectedInstruments, testState, currentStep, setTestState } = context
  const port = connectedInstruments[0];
  const measurement = await runMeasurementStatic(port, testState.test.firmware_version, testState.test.test_definition_id, currentStep.id);
  const testname = testNames[testState.test.test_definition_id][currentStep.id];

  setTestState((prev) => {
    prev.test.raw_result.push({ name: testname, measurement: measurement });
    return prev;
  });

  const submitTestResponse: Response = await submitTestData(testState.test);

  return submitTestResponse.ok
}

export function resetToNew(context: TestContextType) {
  const { setStepsState, setTestState } = context;
  setStepsState({ ...initialStepState, steps: testSteps.map((step) => Object.assign({}, step)) });
  setTestState((prev) => ({ ...testInitialState, tests: prev.tests, test: { ...testInitialState.test, firmware_version: prev.test.firmware_version, raw_result: [] } }));
}
