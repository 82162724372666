import React from "react";

import UnselectButton from "../../packs/images/close-button.png";

const SelectedPet: React.FC<{ setFormstate; petSelected; setPetSelected; clearSelectedPet; setFormMessage }> = ({ setFormstate, petSelected, setPetSelected, clearSelectedPet, setFormMessage }) => {
  return (
    <div className="d-flex border form-control">
      <div className="d-flex justify-content-around w-100">
        <div className="mx-5 w-75">
          <div>
            <small>Pet</small>
            <p>
              <span className="fw-bold">{petSelected.name}</span> - {petSelected.species}
            </p>
          </div>
          <div>
            <small>owner name</small>
            <p>
              {petSelected.owner} {petSelected.family_name}
            </p>
          </div>
        </div>

        <div className="mx-5 w-75">
          <div>
            <small>Patient ID</small>
            {petSelected.patient_id ? <p>{petSelected.patient_id}</p> : <p>-</p>}
          </div>
          <div>
            <small>owner phone no.</small>
            {petSelected.phone ? <p>{petSelected.phone}</p> : <p>-</p>}
          </div>
        </div>
      </div>

      <div className="my-auto">
        <button
          className="btn"
          onClick={(e) => {
            setFormstate(FORM_STATES.PETSELECT);
            setPetSelected("");
            clearSelectedPet("");
            setFormMessage("");
          }}
        >
          <img src={UnselectButton} />
        </button>
      </div>
    </div>
  );
};

enum FORM_STATES {
  PETSELECT,
  SELECTEDPET,
  NEWPET,
}

export default SelectedPet;
