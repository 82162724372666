import React, { useContext } from "react";

import PetSelect from "../PetSelect";
import NewPetForm from "./NewPetForm";
import SelectedPet from "./SelectedPet";
import { TestContextType, FormMessage, MessageTextclass } from "../../utils/types";
import { TestContext, resolveBtn } from "../../utils/runTest";
import { IPet } from "../../utils/interfaces";

const SelectPetDetails: React.FC = () => {
  const context = useContext<TestContextType>(TestContext);
  const { testState, setTestState, currentStep } = context;
  let status: FORM_STATES;
  let label;
  let content;
  if (testState.selectedPet?.id) status = FORM_STATES.SELECTEDPET;

  const [formstate, setFormstate] = React.useState<FORM_STATES>(status);
  const [petSelected, setPetSelected] = React.useState<IPet>(testState.selectedPet);
  const [formMessage, setFormMessage] = React.useState<FormMessage>(null);
  const [searchedPetName, setSearchedPetName] = React.useState<string>("");


  const { sample_area, sample } = testState.test;

  function handlePetSelection(pet: IPet) {
    setTestState({
      ...testState,
      selectedPet: pet,
      test: {
        ...testState.test,
        pet_id: pet?.id,
      },
    });
    if (pet) {
      resolveBtn({ context, disabled: false, on: "next" });
    } else {
      resolveBtn({ context, disabled: true, on: "next" });
    }
  }

  function handleSample(event) {
    setTestState({
      ...testState,
      test: {
        ...testState.test,
        sample: event.target.value,
      },
    });
  }

  function handleSampleArea(event) {
    setTestState({
      ...testState,
      test: {
        ...testState.test,
        sample_area: parseInt(event.target.value),
      },
    });
  }

  switch (formstate) {
    case FORM_STATES.PETSELECT:
      content = (
        <PetSelect
          onChange={(e) => handlePetSelection(e[0])}
          autofocus={true}
          selected_pet={testState.selectedPet}
          setFormstate={setFormstate}
          setPetSelected={setPetSelected}
          setSearchedPetName={setSearchedPetName}
          searchedPetName={searchedPetName}
        />
      );
      label = "Search a Pet";
      break;

    case FORM_STATES.SELECTEDPET:
      content = (
        <SelectedPet setFormstate={setFormstate} petSelected={petSelected} setPetSelected={setPetSelected} clearSelectedPet={(e) => handlePetSelection(e[0])} setFormMessage={setFormMessage} />
      );
      label = `Selected Pet #${testState.selectedPet?.id}`;

      break;

    case FORM_STATES.NEWPET:
      content = (
        <NewPetForm
          setFormstate={setFormstate}
          setPetSelected={setPetSelected}
          setFormMessage={setFormMessage}
          handlePetSelection={(e: IPet) => handlePetSelection(e)}
          searchedPetName={searchedPetName}
        />
      );
      label = "Add a New Pet";

      break;

    default:
      content = (
        <PetSelect
          onChange={(e) => handlePetSelection(e[0])}
          autofocus={true}
          selected_pet={testState.selectedPet}
          setFormstate={setFormstate}
          setPetSelected={setPetSelected}
          setSearchedPetName={setSearchedPetName}
          searchedPetName={searchedPetName}
        />
      );
      label = "Search a Pet";

      break;
  }
 
  const messageTextClass: MessageTextclass = {
    error: "text-danger",
    success: "text-success",
  };

  return (
    <div className="select-pet">
      <h3 className="text-center mb-3 mt-2">{currentStep?.description}</h3>
      <div>
        <div className="d-flex justify-content-between">
          <label className={"form-label " + (formstate == FORM_STATES.SELECTEDPET ? "fw-bold" : "")}>{label}</label>
          <p className={messageTextClass[formMessage?.type]}>{formstate !== FORM_STATES.PETSELECT && formMessage?.message}</p>
        </div>
        <div className="field mb-3">{content}</div>
        <div className="field mb-3">
          <label className="form-label" htmlFor="test_sample_area">
            Sample Area
          </label>
          <br />

          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="sample_area" id="sample_area_left" value={0} onChange={(e) => handleSampleArea(e)} checked={sample_area == 0} />
            <label className="form-check-label" htmlFor="sample_area_left">
              Left Ear
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="sample_area" id="sample_area_right" value={1} onChange={(e) => handleSampleArea(e)} checked={sample_area == 1} />
            <label className="form-check-label" htmlFor="sample_area_right">
              Right Ear
            </label>
          </div>
        </div>

        <div className="field">
          <label className="form-label" htmlFor="test_sample">
            Sample ID
          </label>
          <input className="form-control" type="text" name="test[sample]" id="test_sample" placeholder="(optional)" value={sample || ""} onChange={(e) => handleSample(e)} />
        </div>
      </div>
    </div>
  );
};

enum FORM_STATES {
  PETSELECT,
  SELECTEDPET,
  NEWPET,
}

export default SelectPetDetails;
