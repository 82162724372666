import { FunctionComponent, ReactElement } from "react";
import { IPet, TestParams } from "./interfaces";

// Stepper
export interface Step {
  id: TEST_STEPS;
  title: string;
  comp: FunctionComponent<any>;
  description?: string;
  status: STEP_STATUS;
  next?: TEST_STEPS;
  prev?: TEST_STEPS;
  initial?: boolean;
  last?: boolean;
  guards?: {
    on: {
      next?: (context: TestContextType) => void | boolean;
      prev?: (context: TestContextType) => void | boolean;
    };
  };
}

export type ResolveBtn = { context: TestContextType; disabled: boolean; label?: string; action?: (context: TestContextType) => void; on: "next" | "prev" };

export enum STEP_STATUS {
  INCOMPLETE = "INCOMPLETE",
  INPROGRESS = "INPROGRESS",
  DONE = "DONE",
}

export enum TEST_STEPS {
  CONNECT = 1,
  ADD_PET = 2,
  SELECT_TEST = 3,
  ADD_BLANK = 4,
  ADD_SAMPLE = 5,
  COMPLETE = 6,
}

export type Steps = Step[];

export type StepsStateType = {
  steps: Steps;
  currentStep: Step;
  btn: {
    next: { label: string; disabled: boolean; action: (context: TestContextType) => void };
    prev: { label: string; disabled: boolean; action: (context: TestContextType) => void };
  };
};

export type TestContextType = {
  steps: Steps;
  currentStep: Step;
  stepsState: StepsStateType;
  setStepsState: React.Dispatch<React.SetStateAction<StepsStateType>>;
  setTestState: React.Dispatch<React.SetStateAction<TestStateType>>;
  testState: TestStateType;
  countDown: number;
  setCountDown: React.Dispatch<React.SetStateAction<number>>;
  connectedInstruments: SerialPort[];
  setConnectedInstruments: React.Dispatch<React.SetStateAction<SerialPort[]>>;
  isDeviceConnected: boolean;
  measurementTimer: number;
  setMeasurementTimer: React.Dispatch<React.SetStateAction<number>>;
};

export type TestStateType = {
  selectedPet: IPet;
  test: TestParams;
  measurement: RunningMeasurement;
  tests: Test[];
  alerts: { message: string; type: "error" | "info" | "default"; clear?: boolean }[];
  measurementSuccessAlert: { message: string }
  pairingInstrument: boolean;
};

export type RunningMeasurement = { inProgress: boolean, message: string, description?: string, runTimer: boolean }

export enum TESTS {
  RAPID_TEST = 1,
  OVERNIGHT_TEST = 2,
  EIS_TEST = 3,
}

export type Test = {
  id: TESTS;
  name: string;
  test_information: string;
  description: string;
  status: TEST_DEFINITION_STATUS
};

export enum TEST_DEFINITION_STATUS{
  BETA = 'beta',
  PRODUCTION = 'production',
  ARCHIVED = 'archived',
}

export type PerformanceData = {
  overall_accuracy: string;
  sensitivity: string;
  specificity: string;
};

export type FormMessage = {
  message: string;
  type: "error" | "success" ;
}

export type MessageTextclass = {
  [key in "error" | "success"]: string;
};

export type AddSampleInstructionsType = {
  [key in TESTS]: {
    heading: string;
    instructions: React.FC[];
  };
};
