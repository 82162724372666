import React, { useContext } from "react";
import otterLogo from "../../../assets/images/otter-new-logo.svg";
import InstrumentConnectionStatus from "./InstrumentConnectionStatus";
import { TestContextType } from "../../utils/types";
import { TestContext } from "../../utils/runTest";

type Props = {};

const Header: React.FC<Props> = (props) => {
  const { testState } = useContext<TestContextType>(TestContext);
  return (
    <div className="d-flex justify-content-between p-3 pb-1">
      <div className="flexed-center gap-3">
        <img src={otterLogo} width="90" />
        <InstrumentConnectionStatus />
      </div>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" disabled={testState.measurement.inProgress}></button>
    </div>
  );
};

export default Header;
