import { TESTS, TEST_STEPS } from "../../../utils/types";
import {
  RAPID_OVERNIGHT_13,
  RAPID_OVERNIGHT_12,
  EIS_12,
  EIS_13,
} from "./methodscripts";

const TestMethodScriptVersionMapping: TestMethodScriptVersionType[] = [
  // JSON data for mapping firmware version and testname with method script commands
  {
    version: "1.2",
    scripts: [
      {
        name: [TESTS.RAPID_TEST, TESTS.OVERNIGHT_TEST],
        methodscript: RAPID_OVERNIGHT_12,
      },
      {
        name: [TESTS.EIS_TEST],
        methodscript: EIS_12,
      },
    ],
  },
  {
    version: "1.3",
    scripts: [
      {
        name: [TESTS.RAPID_TEST, TESTS.OVERNIGHT_TEST],
        methodscript: RAPID_OVERNIGHT_13,
      },
      {
        name: [TESTS.EIS_TEST],
        methodscript: EIS_13,
      },
    ],
  },
];

export function getMethodScript(testName: TESTS, version: string): MethodScripts {
  // funtion which returns the approprate methodscript based on testname and firmware version
  const versionData: TestMethodScriptVersionType = TestMethodScriptVersionMapping.find((data) => data.version === version);
  if (!versionData) return;

  const script: Scriptstype = versionData.scripts.find((mScriptVersion) => mScriptVersion.name.includes(testName));

  if (!script) return;
  return script.methodscript;
}


interface TestMethodScriptVersionType {
  version: string
  scripts: Scriptstype[]
}

interface Scriptstype{
  name: TESTS[]
  methodscript: MethodScripts
}

export type MethodScripts = {
  [key in TEST_STEPS]?: string[];
}
