import React, { useContext, useEffect } from "react";
import { TestContextType } from "../../utils/types";
import { TestContext } from "../../utils/runTest";

type Props = {
  message: string;
  type: "error" | "info" | "default";
  index: number;
  clear?: boolean;
};

const AlertMessage: React.FC<Props> = ({ message, type, index, clear = false }) => {
  const { setTestState } = useContext<TestContextType>(TestContext);
  useEffect(() => {
    setTimeout(() => {
      if (clear) {
        setTestState((prev) => ({ ...prev, alerts: [...prev.alerts.splice(index + 1, 1)] }));
      }
    }, 5000);
  }, []);
  return <div className={`test-alerts test-alerts-${type}`}>{message}</div>;
};

export default AlertMessage;
