import { TestParams } from "./interfaces";
import { firmwareVersions } from "./runTest";
import { Test } from "./types";

const token: string = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content") || "";

export const getAvailableTests = async (serialNumber: string): Promise<{available_tests: Test[], message: string}> => {
  const tests: {available_tests: Test[], message: string}  = await fetch(`/available_tests?serial_number=${serialNumber}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": token,
    },
  })
    .then(async (res) => await res.json())
    .catch((err) => console.log(err));

  return tests;
}


export async function submitTestData(test: TestParams): Promise<Response> {
  const testParams: TestParams = { ...test, firmware_version: firmwareVersions[test.firmware_version] };
  testParams.mscript_version = testParams.firmware_version;

  const response = await fetch("/tests", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(testParams),
  });

  return response;
}
