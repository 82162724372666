import * as React from "react";
import { DateTime } from "luxon";

const LocalizeDate = ({ date }) => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  const formatted = DateTime.fromISO(date)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_SHORT);

  return (
    <>
    { date  ? (
      formatted
    ): (
      <span>Date not found</span>
    )}
    </>
    );
};

export default LocalizeDate;
