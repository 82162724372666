import React, { useEffect, useState } from "react";
import Stepper from "../stepper/Stepper";
import Header from "./Header";

// utils
import { TestContext, testInitialState, getConnectionStatus, nextStepResolver, initialStepState, resetToNew, instrumentConnectCallback } from "../../utils/runTest";
import { StepsStateType, TestContextType, TestStateType } from "../../utils/types";
import Footer from "./Footer";
import { getPorts, serial } from "../../packs/palmsense_lib/actions";
import AlertMessage from "./AlertMessage";

type Props = {};

const RunTest: React.FC<Props> = () => {
  const [stepsState, setStepsState] = useState<StepsStateType>(initialStepState);
  const [testState, setTestState] = useState<TestStateType>(testInitialState);
  const [connectedInstruments, setConnectedInstruments] = useState<SerialPort[]>([])
  const [measurementTimer, setMeasurementTimer] = useState<number>(0)
  const [countDown, setCountDown] = useState<number>(60);

  const { steps, currentStep } = stepsState;
  const CurrentComp = currentStep.comp;

  const context: TestContextType = {
    steps,
    currentStep,
    stepsState,
    setStepsState,
    testState,
    setTestState,
    countDown,
    setCountDown,
    connectedInstruments,
    setConnectedInstruments,
    measurementTimer,
    setMeasurementTimer,
    isDeviceConnected: (getConnectionStatus(connectedInstruments).isConnected && !!testState.tests.length),
  };

  useEffect(() => {
    // get instruments
    handleDeviceStatus();

    // add listener to trigger when devices connect / disconnect
    serial.addEventListener("connect", () => handleDeviceStatus());
    serial.addEventListener("disconnect", () => handleDeviceStatus());
  }, []);

  useEffect(() => {
    instrumentConnectCallback(context);
  }, [connectedInstruments]);

  async function handleDeviceStatus() {
    console.log("Handling device connection status...");
    setConnectedInstruments(await getPorts());
  }

  return (
    <div className="modal fade" id="newTestModal" tabIndex={-1} aria-labelledby="newTestModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content ">
          <TestContext.Provider value={context}>
            <div className="modal-body h-100 run-test-modal__body">
              <Header />

              <>
                <div className="w-75 m-0 my-0 mx-auto mt-4 mb-5">
                  <Stepper steps={steps} />
                </div>

                <div className="step-component">
                  <CurrentComp />
                </div>
              </>

              {testState.alerts.map((alert, i) => (
                <AlertMessage message={alert.message} type={alert.type} index={i} clear={alert.clear} key={i}/>
              ))}
              <Footer />
            </div>
          </TestContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default RunTest;
