import React from "react";
import { Steps, Step, STEP_STATUS } from "../../utils/types";
import { stepStateClasses } from "../../utils/runTest";
import checkIcon from "../../../assets/images/check.svg";

type Props = {
  steps: Steps;
};

const Stepper: React.FC<Props> = (props) => {
  const { steps } = props;
  return (
    <div className="stepper">
      {steps.map((step, i) => (
        <StepComponent step={step} index={i + 1} key={i} />
      ))}
    </div>
  );
};

const StepComponent: React.FC<{ step: Step, index: number }> = ({ step, index }) => {
  const stepClass = !(step.initial) ? "step step__flex" : "step";
  const stepLinkClass = `step__link ${(step.status == STEP_STATUS.INCOMPLETE) && "step__link-incomplete"}`
  return (
    <div className={stepClass}>
      {(step.prev || step.last) && <div className={stepLinkClass}></div>}

      <div className={`step__circle ${stepStateClasses[step.status]}`}>
        {step.status == STEP_STATUS.DONE ? <img src={checkIcon} alt="" /> : index}
        <h6 className="step-title">{step.title}</h6>
      </div>
    </div>
  );
};

export default Stepper;
