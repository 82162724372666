import { TESTS, TEST_STEPS } from "../../utils/types";
import { MethodScripts, getMethodScript } from "./methodscript/getmethodscript";

export class Instrument {
    static serial = navigator.serial;

    // serial options from UART settings from emstat protocol docs
    // https://d4ceckwy45dem.cloudfront.net/wp-content/uploads/2020/04/Emstat-Pico-communication-protocol-V1.2.pdf
    static serialOptions: SerialOptions = {
        baudRate: 230400,
        parity: "none",
        dataBits: 8,
        stopBits: 1,
    }


    protected portStatus: ConnectionStatus;
    readonly port: SerialPort;
    protected serialNumber: string;
    protected firmwareVersion: string;

    constructor(port: SerialPort) {
        this.port = port;
        console.log("Instrument before init: ", this);
        // this.flush(); // always flush at the time of connection
        this._initialize();
    }

    protected async _initialize() {
        await this.open();
        const { serialNumber, firmwareVersion } = await this.getInstrumentInfo();
        this.serialNumber = serialNumber;
        this.firmwareVersion = firmwareVersion;
        console.log("Instrument after init: ", this);
        await this.close();
    }

    async runMeasurement() {
        const complexScript = [
            "e",
            "var c",
            "var p",
            "var f",
            "var g",
            "set_pgstat_chan 1",
            "set_pgstat_mode 0",
            "set_pgstat_chan 0",
            "set_pgstat_mode 3",
            "set_max_bandwidth 3200",
            "set_pot_range -500m 500m",
            "set_cr 59u",
            "set_autoranging 59u 59u",
            "cell_on",
            "meas_loop_ca p c -500m 500m 2",
            "pck_start",
            "pck_add p",
            "pck_add c",
            "pck_end",
            "endloop",
            "meas_loop_swv p c f g -500m 0m 4m 25m 400",
            "pck_start",
            "pck_add p",
            "pck_add c",
            "pck_add f",
            "pck_add g",
            "pck_end",
            "endloop",
            "on_finished:",
            "cell_off"
        ];
        let command = complexScript.map(command => command.concat("\n"));
        command.push("\n");
        await this.write(command);
        const val = await this.read();
        console.log("Complex stript result: ", val);
    }

    protected async getInstrumentInfo(): Promise<DeviceInfo> {
        console.log("Fetching instrument specific information");
        try {
            this.portStatus = ConnectionStatus.Connected;
            console.log("Port opened successfully with the device");
            console.log("Reading for Serial");
            console.log(this.port);
            await this.write(["i\n"]);
            let serialNumber = await this.read();
            serialNumber = serialNumber.trim();

            console.log("Reading for firmware");
            console.log(this.port);
            await this.write(["t\n"]);
            console.log("New Write complete");
            const firmwareVersion = await this.read();
            return { serialNumber, firmwareVersion, errorCode: "" };
        } catch (error) {
            this.portStatus = ConnectionStatus.Failed;
            console.log("Error opening / reading port for the device");
        }
    }

    protected getFirmwareVersion(): string {
        return this.firmwareVersion;
    }

    protected async write(command: string[]) {
        const textEncoderStream = new TextEncoderStream();
        const streamClosed = textEncoderStream.readable.pipeTo(this.port.writable);
        const writer = textEncoderStream.writable.getWriter();

        console.log("Writing to device");
        for (const line of command) {
            await writer.write(line);
        }
        console.log("Writing to device complete");
        await writer.close();
        console.log("Writer closed");
    }

    protected async read(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            console.log("start reading result..");
            const textDecoder = new TextDecoder();
            // const textDecoderStream = new TextDecoderStream();
            // const readableStreamClosed = this.port.readable.pipeTo(textDecoderStream.writable);
            // const reader = textDecoder.readable.getReader();
            const reader = this.port.readable.getReader();
            let data: string = "";
            try {
                let readMore = true;
                // Listen to data coming from the serial device.
                while (readMore) {
                    const readData = await reader.read();
                    const value = textDecoder.decode(readData.value);
                    data = data.concat(value);
                    // console.log("reading result in interation, value: ", value);

                    if(data && data.length > 0 && data.startsWith("i")){
                        if (value.endsWith("\n")) {
                            // console.log("Found end statement while reading", value);
                            readMore = false;
                        }
                    }

                    if (data && data.length > 0 && data[0].startsWith("t")){
                        if (value.endsWith("*\n")) {
                            // console.log("Found end statement while reading", value);
                            readMore = false;
                        }
                    }

                    if (data && data.length > 0 && data[0].startsWith("e")){
                        // console.log("Result of a method script!");

                    }

                    if (value == '\n' || value.includes("!") || value.endsWith("\n\n")) {
                        // console.log("Found end statement while reading", value);
                        readMore = false;
                    }
                }

                await reader.cancel();
                resolve(data);
            } catch (error) {
                console.log("Error reading from the device");
                reject(error);
            }
        })
    }

    getSerial(): string {
        return this.serialNumber;
    }

    async open(): Promise<void> {
        if (!this.port.readable || !this.port.writable) {
            await this.port.open(Instrument.serialOptions);
        }
        return;
    }

    close(): Promise<void> {
        return this.port.close();
    }

    forceClose(): void {
        if (this.port.readable) {
            this.port.readable.cancel();
        }

        if (this.port.writable){
            this.port.writable.abort();
        }

        this.close();
    }

    isOpen(): boolean {
        return (this.port.readable !== null && this.port.writable !== null);
    }

    flush(): void {
        console.log("Flushing buffer memory on port");
        this.read();
        console.log("Flush complete");
    }

    execute(command: string, terminal?: string): string {
        if(command == null){
            console.warn("command can't be null while executing instrument");
            return;
        }

        this.flush();

        console.warn("Method not implemented");
        return;
    }

}

export async function openStatic(port: SerialPort): Promise<void> {
    await port.open(Instrument.serialOptions);
    return;
}

export async function readStatic(port: SerialPort): Promise<string> {
    return new Promise(async (resolve, reject) => {
        console.log("start reading result..");
        const textDecoder = new TextDecoder();
        const reader = port.readable.getReader();
        let data: string = "";
        try {
            let readMore = true;
            // Listen to data coming from the serial device.
            while (readMore) {
                const readData = await reader.read();
                const value = textDecoder.decode(readData.value);
                data = data.concat(value);
                // console.log("reading result in interation, value: ", value);

                if(data.length === 0){
                    continue;
                }

                if(data.includes("!") || value.endsWith("\n\n")){
                    console.log("Found end statement while reading", value);
                    readMore = false;
                }

                if(data.startsWith("i") && data.endsWith("\n")){
                    console.log("Found end statement while reading", value);
                    readMore = false;
                }

                if (data.startsWith("t") && data.endsWith("*\n")){
                    console.log("Found end statement while reading", value);
                    readMore = false;
                }

                if (data.startsWith("e") && data.endsWith("*\n\n")){
                    console.log("Result of a method script!");
                    readMore = false;
                }
            }

            await reader.cancel();
            resolve(data);
        } catch (error) {
            console.log("Error reading from the device");
            reject(error);
        }
    })
}

export async function writeStatic(port: SerialPort, command: string[]) {
    const textEncoderStream = new TextEncoderStream();
    const streamClosed = textEncoderStream.readable.pipeTo(port.writable);
    const writer = textEncoderStream.writable.getWriter();

    console.log("Writing to device");
    for (const line of command) {
        await writer.write(line);
    }
    console.log("Writing to device complete");
    await writer.close();
    console.log("Writer closed");
}

export let serialOptionsStatic: SerialOptions = {
    baudRate: 230400,
    parity: "none",
    dataBits: 8,
    stopBits: 1,
}

export async function runMeasurementStatic(port: SerialPort, firmwareVersion: string, testId: number, stepId: TEST_STEPS) {
    await openStatic(port);
    const mScript: MethodScripts = getMethodScript(testId, firmwareVersion)

    let command = mScript[stepId].map(command => command.concat("\n"));
    command.push("\n");
    await writeStatic(port, command);
    const measurement = await readStatic(port);
    // const measurement = "await readStatic(port) !1234 asldfkasd";
    port.close();

    const errorAtIndex = measurement.indexOf("!");
    if (errorAtIndex != -1) {
        const errorCode = measurement.substr(errorAtIndex+1, 4);
        throw new Error("Error running the measurement for sample, error code: "+ errorCode);
    } else {
        return measurement
    }
}

 export async function getInstrumentInfo(port :SerialPort): Promise<DeviceInfo> {

    await openStatic(port)
    const script :string[] =["t\n","i\n","\n" ];

    await writeStatic(port,script);
    const deviceData = await readStatic(port);

    const deviceInfo :string[] = deviceData.split('R*')
    const firmwareData :string = deviceInfo[0]
    const errorCode: string = deviceInfo[1].match(/!.*/)?.[0]
    const serialNumber :string = deviceInfo[1].trim().slice(1)

    const firmwareText :string[] = firmwareData.split('#')
    const numbers :string = firmwareText[0].match(/\d+/g).join("");
    let firmwareVersion :string;
    firmwareVersion = numbers[0]+'.'+ numbers[1]

    port.close()

   return { firmwareVersion, serialNumber, errorCode };
 }

export function getInstrumentError(errorCode: string): { message: string } {
  const errors = {
    "!0006": {
      message: "We experienced a connection interruption during your last test. Please disconnect and reconnect the instrument.",
    },
    default: {
      message: "A connection error occurred between the instrument and the application.",
    },
  };

  return errors[errorCode] || errors["default"];
}

export interface DeviceInfo {
  serialNumber: string;
  firmwareVersion: string;
  errorCode: string;
}

enum ConnectionStatus {
    Connected,
    Failed
}
