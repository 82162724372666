import React, { useContext, useEffect } from "react";
import PlusIcon from "../../../assets/images/plus-icon.svg";
import ChevronLeftIcon from "../../../assets/images/chevron-left-icon.svg";
import UsbConnected from "../../packs/images/usb-connected.png";
import { requestOptions, serial } from "../../packs/palmsense_lib/actions";
import { TestContext, nextStepResolver } from "../../utils/runTest";
import { TestContextType } from "../../utils/types";
import Loader from "./Loader";

type Props = {};

const PairNewInstrument: React.FC<Props> = () => {
  const context = useContext<TestContextType>(TestContext);
  const { currentStep, setConnectedInstruments, isDeviceConnected, testState, setTestState } = context;

  useEffect(() => {
    if (isDeviceConnected) {
      nextStepResolver(context);
    }
  }, []);

  async function pairNewDevice() {
    try {
      const port = await serial.requestPort(requestOptions);
      setConnectedInstruments([port]);
      setTestState((testState) => ({ ...testState, alerts: [] }));
    } catch (error) {}
  }

  return testState.pairingInstrument ? (
    <Loader title="Connecting Instrument" description="Please Stay on the Screen" />
  ) : isDeviceConnected ? (
    <InstrumentConnected context={context} />
  ) : (
    <>
      <h3 className="text-center mb-3 mt-2">{currentStep?.description}</h3>
      <div className="h-75 mt-4 pt-4">
        <div className="pair-new-instrument">
          <StepToConnect number={1} title="Connect your Instrument" description="connect your instrument using USB port to your computer" key={1} />
          <StepToConnect number={2} title="Pair New or Connect Paired Instrument" description="click on pair new instrument and select your instrument for new pairing" key={2} />

          <button className="stepper__btn" onClick={pairNewDevice}>
            {" "}
            <img src={PlusIcon} alt="" className="stepper__btn-icon" /> Pair New Instrument
          </button>
        </div>
      </div>
    </>
  );
};

const StepToConnect: React.FC<{ number: number; title: string; description: string }> = ({ number, title, description }) => {
  return (
    <div className="step-to-connect">
      <h6 className="text-nowrap fw-bold">Step {number}:</h6>
      <div className="step-to-connect__details">
        <h6 className="step-to-connect__details-title mb-1 fw-bold">{title}</h6>
        <p className="step-to-connect__details-desc">{description}</p>
      </div>
    </div>
  );
};

const InstrumentConnected: React.FC<{ context: TestContextType }> = ({ context }) => {
  return (
    <div className="flexed-center flex-column gap-3 h-100 w-100">
      <div className="flexed-center gap-3 mb-3">
        <img src={UsbConnected} style={{width: "3rem"}} />
        <h2 className="m-0">Instrument Connected</h2>
      </div>
      <button className="stepper__btn btn-lg" onClick={() => nextStepResolver(context)}>
        Proceed To Next
        <img src={ChevronLeftIcon} style={{rotate: "180deg"}}/>
      </button>
    </div>
  );
};

export default PairNewInstrument;
