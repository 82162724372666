import React from "react";
import { useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";

export const PetOwnerSelect: React.FC<Props> = (props) => {

  const SEARCH_URL = "/search_pet_owners";
  const {onSelect , petOwnerButton, setPetOwnerButton , setSearchedPetOwnerName} = props
  const [state, setState] = useState<PetOwnerSelectState>({ isLoading: false, options: [] });

  const handleSearch = (query) => {
    setState({ isLoading: true, options: [] });
    setSearchedPetOwnerName(query)
    fetch(`${SEARCH_URL}?q=${query}`)
      .then((resp) => resp.json())
      .then((items:IPetOwner[]) => {
        setState({ isLoading: false, options: items });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setState({ isLoading: false, options: [] });
      });
  };

  return (
    <>
      <AsyncTypeahead
        id="pet-owner-select"
        autoFocus
        isLoading={state.isLoading}
        labelKey="firstname"
        filterBy={["firstname", "family_name"]}
        onSearch={handleSearch}
        options={state.options}
        onChange={(selectedOptions) => {
          if (selectedOptions.length > 0) {
            const { id, firstname, family_name } = selectedOptions[0];
            onSelect(firstname, family_name, id);
            setPetOwnerButton(!petOwnerButton);
          }
        }}
        placeholder="Search Owner's First or Family Name"
        renderMenu={(options, menuProps) => (
          <Menu {...menuProps}>
            {options.length > 0 ? <hr /> : null}
            {options.map((option: IPetOwner, index: number) => (
              <MenuItem key={index} option={option} position={index}>
                <li className="d-flex justify-content-between">
                  <div className="mx-3 w-25">
                    <div>
                      <div>
                        <small>First Name</small>
                        <br />
                        <span className="fw-bold">{option.firstname}</span>
                      </div>
                      <div>
                        <small>Family Name</small>
                        <br />
                        <small>{option.family_name}</small>
                      </div>
                    </div>
                  </div>
                  <div className="mx-3 w-25">
                    <div>
                      <div>
                        <small>Phone</small>
                        <br />
                        {option.phone ? (
                          <span>{option.phone}</span>
                        ) : (
                          <span>-</span>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              </MenuItem>
            ))}
            <div className="d-flex justify-content-center mb-2 mt-2">
              <button
                className="btn btn-primary w-75"
                type="button"
                onClick={() => setPetOwnerButton(!petOwnerButton)}
              >
                Add New Pet Owner
              </button>
            </div>
          </Menu>
        )}
      />
    </>
  );
}

interface IPetOwner {
    id: number;
    firstname: string;
    family_name: string;
    phone: string
}

interface PetOwnerSelectState {
    isLoading: boolean;
    options: IPetOwner[]
}

type Props = {
    onSelect(
    firstname: string,
    family_name: string,
    id: number,
    ): void ,
    setPetOwnerButton: React.Dispatch<React.SetStateAction<boolean>>,  
    setSearchedPetOwnerName: React.Dispatch<React.SetStateAction<string>>,
    petOwnerButton: boolean,
}