import React, { useState,useEffect} from "react";
import { IPet } from "../../utils/interfaces";
import {PetOwnerSelect} from "../PetOwnerSelect";
import { FormMessage } from "../../utils/types";

const POST_URL = "/pets";

type Props = {
  setFormstate: React.Dispatch<React.SetStateAction<FORM_STATES>>;
  setPetSelected: React.Dispatch<React.SetStateAction<IPet>>;
  handlePetSelection: (pet: IPet) => void;
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessage>>;
  searchedPetName: string;
};

const NewPetForm: React.FC<Props> = (props) => {
  const { setFormstate, setPetSelected, handlePetSelection, setFormMessage, searchedPetName } = props;

  const token = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

  const [petName, setPetName] = useState<string>(searchedPetName);
  const [firstName, setFirstName] = useState<string>("");
  const [familyName, setFamilyName] = useState<string>("");
  const [petOwnerId,setPetOwnerId] = useState<number>(null);
  const [species, setSpecies] = useState<number>(0);
  const [buttonText, setButtonText] = useState<string>("Add Pet");
  const [isdisabled, setIsDisbaled] = useState<boolean>(false);
  const [isPetOwnerButtonEnabled , setIsPetOwnerButtonEnabled] = useState<boolean>(false);
  const [searchedPetOwnerName , setSearchedPetOwnerName]= useState("")

  const handleSpecies = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpecies(parseInt(e.target.value));
  };

  useEffect(() => {
    setFirstName(searchedPetOwnerName);
  }, [searchedPetOwnerName]);

  const CreatePetAPI = () => {
    try {
      if (petName == "") {
        setFormMessage({ message: "(Pet Name can't be empty)", type: "error" });
        throw new Error("Pet Name can't be empty");
      }
      if (firstName == "") {
        setFormMessage({
          message: "(Owner's First Name can't be empty)",
          type: "error",
        });
        throw new Error("Owner's First Name can't be empty");
      }
      if (familyName == "") {
        setFormMessage({
          message: "(Family Name can't be empty)",
          type: "error",
        });
        throw new Error("Family Name can't be empty");
      }

      let petInfo = {
        name: petName,
        pet_owner_name: firstName,
        family_name: familyName,
        species: species,
      };
      setIsDisbaled(true);
      setFormMessage({ message: "Adding Pet...", type: "success" });

      // API call to create pet in the backend
      fetch(POST_URL, {
        method: "post",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          authenticity_token: `${token}`,
          pet: {
            name: petName,
            species: species,
          },
          pet_owner: {
            id: petOwnerId,
            name: firstName,
            family_name: familyName,
          },
          commit: "Add Pet",
        }),
      })
        // .then((res) => {res.json())
        .then(async (res) => {
          if (res.status >= 500 && res.status < 600) {
            throw new Error(
              "Sever Problem, Please Try to Submit the Form again"
            );
          }

          if (res.status == 422) {
            const resj = await res.json();

            if (resj.pet && resj.pet[0]) {
              throw new Error(`${resj.pet[0]}`);
            } else if (resj.pet_owner && resj.pet_owner[0]) {
              throw new Error(`${resj.pet_owner[0]}`);
            } else if (resj.pet_owner_id && resj.pet_owner_id[0]) {
              throw new Error(`pet_owner_id ${resj.pet_owner_id[0]}`);
            }
          }

          if (res.status >= 300 && res.status < 500) {
            throw new Error(
              "Sever Problem, Please Try to Submit the Form again"
            );
          }

          const pet: IPet = await res.json();
          setFormMessage({
            message: "Pet Added Successfully",
            type: "success",
          });
          setPetSelected(pet);
          handlePetSelection(pet);
          setFormstate(FORM_STATES.SELECTEDPET);
        })
        .catch((error) => {
          setIsDisbaled(false);
          setButtonText("Retry");
          setFormMessage(error.message);
        });
    } catch (error) {
      console.log("Error object: " + error);
    }
  };

  const handlePetOwnerSelect = (selectedFirstName: string, selectedFamilyName: string ,selectedPetOwnerId: number) => {
    setFirstName(selectedFirstName);
    setFamilyName(selectedFamilyName);
    setPetOwnerId(selectedPetOwnerId)
  };


  return (
    <>
    <div className="bg-secondary bg-opacity-10 p-3 rounded-2">
      <div className="field mb-3">
        <input className="form-control" type="text" name="pet_name" id="pet_name" placeholder="Pet Name" onChange={(e) => setPetName(e.target.value)} value={petName} autoFocus />
      </div>

    { isPetOwnerButtonEnabled?  ( 
      <div className="field mb-3">
         <div className="row">
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="pet_owner[name]"
              id="owner_first_name"
              placeholder="Owner's First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </div>
          <div className="col">
            <input className="form-control"
             type="text" 
             name="family_name" 
             id="family_name" 
             placeholder="Family Name" 
             onChange={(e) => setFamilyName(e.target.value)} 
             value={familyName} />
          </div>
        </div> 
      </div> 
      ): <PetOwnerSelect onSelect={handlePetOwnerSelect} petOwnerButton={isPetOwnerButtonEnabled} setSearchedPetOwnerName={setSearchedPetOwnerName} setPetOwnerButton={setIsPetOwnerButtonEnabled}/> }

      <div className="field d-flex justify-content-between mt-4">
        <div className="d-flex align-items-center">
          <div className="me-3">Species</div>

          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="species" id="species_dog" value={0} checked={species == 0} onChange={(e) => handleSpecies(e)} />
            <label className="form-check-label" htmlFor="species_dog">
              DOG
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="species" id="species_cat" value={1} checked={species == 1} onChange={(e) => handleSpecies(e)} />
            <label className="form-check-label" htmlFor="species_cat">
              CAT
            </label>
          </div>
        </div>

        <div>
          <input className="btn btn-outline-secondary rounded-pill me-2" type="button" onClick={() => setFormstate(FORM_STATES.PETSELECT)} value="Cancel" />
          <input className="btn btn-primary rounded-pill" type="button" disabled={isdisabled} onClick={CreatePetAPI} value={buttonText} />
        </div>
      </div>
    </div>
    </>
  );
};

enum FORM_STATES {
  PETSELECT,
  SELECTEDPET,
  NEWPET,
}

export default NewPetForm;
