import { TEST_STEPS } from "../../../utils/types";
import { MethodScripts } from "./getmethodscript";

export const RAPID_OVERNIGHT_12: MethodScripts = {
  [TEST_STEPS.ADD_SAMPLE]: [
    "e",
    "var c",
    "var p",
    "var f",
    "var g",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 3200",
    "set_pot_range -500m 500m",
    "set_cr 59u",
    "set_autoranging 59u 59u",
    "cell_on",
    "meas_loop_ca p c -500m 500m 2",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "on_finished:",
    "cell_off",
  ],
};

export const EIS_12: MethodScripts = {
  [TEST_STEPS.ADD_BLANK]: [
    "e",
    "var c",
    "var p",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 40",
    "set_pot_range 0 0",
    "cell_off",
    "set_cr 2950u",
    "meas_loop_ocp p 100m 300100m",
    "pck_start",
    "pck_add p",
    "pck_end",
    "endloop",
    "cell_off",
    "wait 20",
    "var h",
    "var r",
    "var j",
    "var x",
    "var y",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 100k",
    "set_pot_range -250m -250m",
    "set_cr 2950u",
    "set_autoranging 590n 2950u",
    "cell_on",
    "meas_loop_ca x y -250m 500m 3",
    "pck_start",
    "pck_add x",
    "pck_add y",
    "pck_end",
    "endloop",
    "set_cr 2950u",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_cr 2950u",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_cr 2950u",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "on_finished:",
    "cell_off",
  ],
  [TEST_STEPS.ADD_SAMPLE]: [
    "e",
    "var h",
    "var r",
    "var j",
    "var c",
    "var p",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 100k",
    "set_pot_range -250m -250m",
    "set_cr 2950u",
    "set_autoranging 590n 2950u",
    "cell_on",
    "meas_loop_ca p c -250m 500m 3",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_end",
    "endloop",
    "set_cr 2950u",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_cr 2950u",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_cr 2950u",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "on_finished:",
    "cell_off",
  ],
};

export const RAPID_OVERNIGHT_13: MethodScripts = {
  [TEST_STEPS.ADD_SAMPLE]: [
    "e",
    "var c",
    "var p",
    "var f",
    "var g",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 3200",
    "set_pot_range -500m 500m",
    "set_cr 59u",
    "set_autoranging 59u 59u",
    "cell_on",
    "meas_loop_ca p c -500m 500m 2",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "meas_loop_swv p c f g -500m 0m 4m 25m 400",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_add f",
    "pck_add g",
    "pck_end",
    "endloop",
    "on_finished:",
    "cell_off",
  ],
};

export const EIS_13: MethodScripts = {
  [TEST_STEPS.ADD_BLANK]: [
    "e",
    "var c",
    "var p",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 40",
    "set_range_minmax da 0 0",
    "set_range ab 4200m",
    "set_autoranging ab 4200m 4200m",
    "set_range ba 2950u",
    "set_autoranging ba 2950u 2950u",
    "cell_off",
    "meas_loop_ocp p 100m 300100m",
    "pck_start",
    "pck_add p",
    "pck_end",
    "endloop",
    "cell_off",
    "wait 20",
    "var h",
    "var r",
    "var j",
    "var x",
    "var y",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 100k",
    "set_range_minmax da -250m -250m",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_e -250m",
    "cell_on",
    "meas_loop_ca x y -250m 500m 3",
    "pck_start",
    "pck_add x",
    "pck_add y",
    "pck_end",
    "endloop",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_range ab 4200m",
    "set_autoranging ab 4200m 4200m",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_range ab 4200m",
    "set_autoranging ab 4200m 4200m",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_range ab 4200m",
    "set_autoranging ab 4200m 4200m",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "on_finished:",
    "cell_off",
  ],
  [TEST_STEPS.ADD_SAMPLE]: [
    "e",
    "var h",
    "var r",
    "var j",
    "var c",
    "var p",
    "set_pgstat_chan 1",
    "set_pgstat_mode 0",
    "set_pgstat_chan 0",
    "set_pgstat_mode 3",
    "set_max_bandwidth 100k",
    "set_range_minmax da -250m -250m",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_e -250m",
    "cell_on",
    "meas_loop_ca p c -250m 500m 3",
    "pck_start",
    "pck_add p",
    "pck_add c",
    "pck_end",
    "endloop",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_range ab 4200m",
    "set_autoranging ab 4200m 4200m",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_range ab 4200m",
    "set_autoranging ab 4200m 4200m",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "set_range ba 118u",
    "set_autoranging ba 3687500p 118u",
    "set_range ab 4200m",
    "set_autoranging ab 4200m 4200m",
    "meas_loop_eis h r j 10m 100k 2 47 -250m",
    "pck_start",
    "pck_add h",
    "pck_add r",
    "pck_add j",
    "pck_end",
    "endloop",
    "on_finished:",
    "cell_off",
  ],
};
