import React from 'react'

type Props = {
  title: string;
  description: string;
};

const Loader: React.FC<Props> = ({ title, description }) => {
  return (
    <div className="h-100 w-100 m-auto">
      <div className="flexed-center gap-3 h-100">
        <Spinner />
        <div>
          <p className="m-0 fs-4">{title}</p>
          <p className="fw-light text-muted fs-6 m-0">{description}</p>
        </div>
      </div>
    </div>
  );
};

export const Spinner: React.FC = () => {
  let radius = 20;

  return (
    <svg className="spinner" viewBox="0 0 50 50">
      <circle className='background' cx="25" cy="25" r={radius} fill="none"></circle>
      <circle className="foreground" cx="25" cy="25" r={radius} fill="none"></circle>
    </svg>
  )
}

export default Loader
