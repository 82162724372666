import React, { useContext } from "react";
import { TestContextType } from "../../utils/types";
import { TestContext, nextStepResolver } from "../../utils/runTest";

const SkipTimerButton = () => {
  const context = useContext<TestContextType>(TestContext);
  return <button className="stepper__btn" onClick={() => nextStepResolver(context)}>Skip Timer & Proceed</button>;
};

export default SkipTimerButton;
