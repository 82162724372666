import React, { useContext } from "react";
import { TestContext, prevStepResolver } from "../../utils/runTest";
import ChevronLeftIcon from "../../../assets/images/chevron-left-icon.svg";
import { TESTS, TestContextType } from "../../utils/types";
import SkipTimerButton from "./SkipTimerButton";

type Props = {};

const Footer: React.FC<Props> = (props: Props) => {
  const context = useContext<TestContextType>(TestContext);
  const { currentStep, stepsState, isDeviceConnected, countDown, testState } = context;

  const timerRequired = testState.test.test_definition_id == TESTS.RAPID_TEST && countDown > 0;

  const fillerStyles = {
    height: "100%",
    width: `${countDown * 1.5}%`,
    backgroundImage: "linear-gradient(to right, #624AF2 , #50DDC3)",
    boxShadow: "#cfcfcf 0px 1px 2px inset",
    borderRadius: "inherit",
  };

  // timebar component
  const timerbar = (
    <div className=" w-100 mx-4">
      <div className="d-flex flex-row justify-content-between align-items-center text-center timebar-container">
        <div style={fillerStyles}></div>
        <span className="timebar-label">{countDown}s</span>
      </div>
    </div>
  );

  return (
    <div className="test-modal__footer">
      {currentStep.prev && (
        <button className="stepper__btn" onClick={() => prevStepResolver(context)} disabled={isDeviceConnected && stepsState.btn.prev.disabled}>
          <img src={ChevronLeftIcon} alt="" />
          {stepsState.btn.prev.label}
        </button>
      )}
      {timerRequired && timerbar}

      <div className="test-modal__footer-next-actions">
        {timerRequired && <SkipTimerButton />}
        <button className="stepper__btn" onClick={() => stepsState.btn.next.action(context)} disabled={!isDeviceConnected || stepsState.btn.next.disabled}>
          {stepsState.btn.next.label}
        </button>
      </div>
    </div>
  );
};

export default Footer;
