import React, { useContext } from "react";
import { TestContextType } from "../../utils/types";
import { TestContext, getConnectionStatus } from "../../utils/runTest";

type Props = {};

const InstrumentConnectionStatus = (props: Props) => {
  const { testState, connectedInstruments } = useContext<TestContextType>(TestContext);
  const connectionStatus = getConnectionStatus(connectedInstruments);

  return (
    <div className="instrument-connection">
      <div className={`instrument-connection__indicator ${connectionStatus.connectionClass}`}></div>
      <p className="instrument-connection__status">{connectionStatus.status}</p>
    </div>
  );
};

export default InstrumentConnectionStatus;
