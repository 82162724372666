import AddSampleOnCartridge from "../components/runtest/AddSampleOnCartridge";
import InsertCartridge from "../components/runtest/InsertCartridge";
import { AddSampleInstructionsType, TESTS } from "./types";

export const addSampleInstructions: AddSampleInstructionsType = {
  [TESTS.RAPID_TEST]: {
    heading: "Add Sample on the Cartridge",
    instructions: [InsertCartridge, AddSampleOnCartridge],
  },
  [TESTS.OVERNIGHT_TEST]: {
    heading: "Add Sample on the Cartridge",
    instructions: [InsertCartridge, AddSampleOnCartridge],
  },
  [TESTS.EIS_TEST]: {
    heading: "Add Sample to Blank on the Cartridge",
    instructions: [AddSampleOnCartridge],
  },
};
