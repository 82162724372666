// Run this example by adding <%= javascript_pack_tag 'hello_typescript' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.

import { Instrument } from "./instrument";

export const serial = navigator.serial;

const filterOptions: SerialPortFilter[] = [{usbProductId: 24597, usbVendorId: 1027}];
export const requestOptions: SerialPortRequestOptions = {filters: filterOptions};

export async function pairInstrument(): Promise<Instrument> {
    // when paired for the first time onconnect listener will not be triggered.
    // explicitly add it to the list
    // same goes for disconnect as well
    const port = await serial.requestPort(requestOptions);
    return new Instrument(port);
}

// gets instrument from already paired pool of ports
export async function getInstrument(serialNumber: string): Promise<Instrument> {
    const instruments = await getInstruments();
    return instruments.find(instrument => instrument.getSerial() === serialNumber);
}

// gets all instruments which are already paired
export async function getInstruments(): Promise<Instrument[]> {
    const ports: SerialPort[] = await serial.getPorts();
    return ports.map(port => new Instrument(port));
}

export async function getPorts() {
    return await serial.getPorts();
}