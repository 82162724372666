import * as React from "react"
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";

const SEARCH_URL = "/search_pets";

class PetSelect extends React.Component <IPetSelectProps, IPetSelectState> {

  constructor(props){
    super(props);
    this.state = {isLoading: false, options: []};
  }

  handleSearch = (query: string) => {
    this.setState({isLoading: true});
    this.props.setSearchedPetName(query);

    fetch(`${SEARCH_URL}?q=${query}`)
      .then((resp)=> resp.json())
      .then((items: IPet[]) => {
        this.setState({options: items});
        this.setState({isLoading: false});
      });
  }

  render() {
    const filterBy = () => true;
    return (
      <AsyncTypeahead
        // filterBy={filterBy}
        id="pet-select"
        autocomplete="off"
        value=""
        autoFocus
        defaultInputValue={this.props.searchedPetName}
        isLoading={this.state.isLoading}
        labelKey="name"
        filterBy={['name','owner','patient_id', 'phone', 'family_name']}
        onSearch={this.handleSearch}
        onChange={ (e) => {
          this.props.onChange(e)
          this.props.setFormstate(FORM_STATES.SELECTEDPET)
          this.props.setPetSelected(e[0])
        }}
        options={this.state.options}
        placeholder="Start typing pet name, owner name or family name"
        renderMenu={(options, menuProps) => (
          <Menu {...menuProps}>
            <div className="d-flex justify-content-center">
              <a className="btn btn-primary w-75" onClick={() => {this.props.setFormstate(FORM_STATES.NEWPET);}}>Add New Pet</a>
            </div>
            {options.length > 0 ? (<hr/>) : null }
            {options.map((option, index) => (
              <div key={index}>
              <MenuItem option={option} position={index}>
                <li className="d-flex justify-content-between">
                  <div className="mx-3 w-25">
                    <div>
                      <div>
                        <small>Pet</small><br/>
                        <span className="fw-bold">{option.name}</span> - <small>{option.species}</small>
                      </div>
                      <div>
                        <small>Owner</small><br/>
                        <small>{option.owner} {option.family_name || ""}</small>
                      </div>
                    </div>
                  </div>
                  <div className="mx-3 w-25">
                    <div>
                      <div>
                        <small>Patient ID</small><br/>
                        {option.patient_id ? (<span>{option.patient_id}</span>):(<span>-</span>)}
                      </div>
                      <div>
                        <small>Phone</small><br/>
                        {option.phone ? (<span>{option.phone}</span>):(<span>-</span>)}
                      </div>

                    </div>
                  </div>
                </li>
              </MenuItem>
              {options.length > 1 && options.length !== index+1 ? (<hr/>) : null }
              </div>
            ))}
          </Menu>
        )}
            />
    );
  }
}

interface IPet {
  id: number;
  name: string;
  owner: string;
  species: string;
}

interface OnChangeFunc {
  (pet: IPet): void
}


interface IPetSelectProps {
  onChange: OnChangeFunc;
  autofocus: boolean;
  selected_pet: IPet;
  setFormstate: Function;
  setPetSelected: Function;
  setSearchedPetName: Function;
  searchedPetName: string;
}

interface IPetSelectState {
  isLoading: boolean;
  options: IPet[];
}

enum FORM_STATES {
  PETSELECT,
  SELECTEDPET,
  NEWPET,
 }

export default PetSelect
