import React, { MouseEventHandler, useContext, useEffect } from "react";

import SelectionCard from "./SelectionCard";

import RapidTestImage from "../../packs/images/rapid-test.png";
import OvernightTestImage from "../../packs/images/overnight-test.png";
import EISTestImage from "../../packs/images/eis-test.png";
import { TESTS, TEST_DEFINITION_STATUS, Test, TestContextType } from "../../utils/types";
import { TestContext, onTestSelectCallback, resolveBtn } from "../../utils/runTest";

const images = {
  [TESTS.RAPID_TEST]: RapidTestImage,
  [TESTS.OVERNIGHT_TEST]: OvernightTestImage,
  [TESTS.EIS_TEST]: EISTestImage,
};

const SelectTest: React.FC = () => {
  const context = useContext<TestContextType>(TestContext);
  const { testState, setTestState } = context;

  useEffect(() => {
    onTestSelectCallback(context);
  }, [context.testState.test.test_definition_id]);

  function handleTestKind(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const test_definition_id = event.currentTarget.getAttribute("data-test-test_definition_id");
    setTestState({
      ...testState,
      test: {
        ...testState.test,
        test_definition_id: parseInt(test_definition_id),
      },
    });
  }

  return (
    <div className="select-test">
      <div className="d-flex flex-column align-items-center justify-content-center px-5 mt-2">
        {testState.tests.map((test) => (
          <TestCard test={test} currentTestId={testState.test.test_definition_id} handleTestKind={handleTestKind} key={test.id} />
        ))}
      </div>
    </div>
  );
};

const TestCard: React.FC<{ test: Test; currentTestId: number; handleTestKind: MouseEventHandler<HTMLButtonElement> }> = ({ test, currentTestId, handleTestKind }) => {
  return (
    <button className={"test-card-button " + (test.id == currentTestId ? "selection-active" : "")} id={test.id.toString()} data-test-test_definition_id={test.id.toString()} onClick={handleTestKind}>
      <SelectionCard heading={test.name} stat={test.test_information} info={test.description} image={images[test.id]} beta={test.status === TEST_DEFINITION_STATUS.BETA} />
    </button>
  );
};

export default SelectTest;
