import React, { useContext, useEffect } from "react";
import { TestContextType } from "../../utils/types";
import { TestContext, nextStepResolver, resetToNew, resolveBtn } from "../../utils/runTest";
import CheckIcon from "../../../assets/images/check.svg";

type Props = {};

const TestCompleted: React.FC<Props> = (props: Props) => {
  const context = useContext<TestContextType>(TestContext);

  useEffect(() => {
    nextStepResolver(context);
    resolveBtn({ context, disabled: false, label: "Return To Dashboard", on: "next", action: returnToDashboard})
    context.setTestState((prev) => ({...prev, alerts: []}))
  }, []);

  function returnToDashboard() {
    window.location.href = "/dashboard"
  }

  return (
    <div className="test-completed">
      <div className="test-completed__icon-bg mb-3">
        <img src={CheckIcon} className="test-completed__icon" />
      </div>

      <h3 className="test-completed__info">Test Completed</h3>
      <button className="stepper__btn mt-4 btn-lg" onClick={() => resetToNew(context)}>Run New Test</button>
    </div>
  );
};

export default TestCompleted;
